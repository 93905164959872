
<template>
  <b-modal
      id="editSettingModal"
      ref="modal"
      title="Edit Setting"
      @show="resetModal"
      @hidden="resetModal"
      hide-footer
    >
      <b-form @submit.prevent="update_setting">
          <b-alert show variant="danger" v-if="error" class="text-center">{{msg}}</b-alert>
        <b-form-group label="Key" label-for="key">
        <b-form-input id="key" disabled v-model="key" :class="{ 'is-invalid': isValid && $v.key.$error }"></b-form-input>
        <label class="invalid-feedback" v-if="!$v.key.required">Key is required</label>
        </b-form-group>

        <b-form-group label="Value" label-for="value">
          <b-form-input id="value" v-model="value" :class="{ 'is-invalid': isValid && $v.value.$error }"></b-form-input>
        <label class="invalid-feedback" v-if="!$v.value.required">Value is required</label>
        </b-form-group>

      <b-button type="submit" variant="primary">Update</b-button>
      </b-form>
    </b-modal>
</template>

<script>
import { AppSettingMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      msg:'',
      error: false,
      isValid: false,
      key : '',
      value : '',
      setting_id:0,
    };
  },

  props: {
    selected_key: {
      type: String,
    },
    selected_value: {
      type: String,
    },    
    selected_id:{
      type: Number,
    }
  },
  validations: {
    key: {
      required
    },
    value: {
      required
    },    
  },
  methods: {
    ...AppSettingMethods,
    // reset all fields of modal on click
    resetModal(){
    this.key=this.selected_key;
    this.value=this.selected_value;
    this.setting_id=this.selected_id;
    this.msg='';
    this.error=false;
    this.isValid = false;
    },
    // hit api to update setting
    update_setting() {
      this.msg='';
      this.isValid = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
     this.updateAppSetting({id:this.setting_id,key:this.key,value:this.value}).then(res => {
       this.$refs['modal'].hide();
        if (res.status == 200) {
          this.msg = res.message ? res.message : "Setting updated successfully"
          this.$swal({
            icon : 'success',
            text: this.msg,
            toast:true,
            showCloseButton: false,
            showConfirmButton: false,
            timer: 3000
          }).then(
          this.getAllAppSetting(),
          );
        }
      }).catch(error => {
        this.error = true;
        if (error.data.status == 422) {
          this.msg = "Something went wrong!!!"
        }
        else{
        this.$swal({
            icon : 'error',
            toast : true,
            text: "Failed to save! Try again",
          showCloseButton: false,
          showConfirmButton: false,
          timer: 3000
        });
        }
      })
    },
  },
  mounted(){

  }
};
</script>
