<template>
    <b-modal
            id="addSettingModal"
            ref="modal"
            title="Add Setting"
            @show="resetModal"
            @hidden="resetModal"
            hide-footer
    >
        <b-form @submit.prevent="add_setting" ref="modal">
            <b-alert show variant="danger" v-if="error" class="text-center">{{msg}}</b-alert>
            <b-form-group label="Key" label-for="key">
                <b-form-input id="key" v-model="key" :placeholder="modal_placeholderA"
                              :class="{ 'is-invalid': isValid && $v.key.$error }"></b-form-input>
                <label class="invalid-feedback" v-if="!$v.key.required">Key is required</label>
            </b-form-group>

            <b-form-group label="Value" label-for="value">
                <b-form-input id="value" v-model="value" :placeholder="modal_placeholderB"
                              :class="{ 'is-invalid': isValid && $v.value.$error }"></b-form-input>
                <label class="invalid-feedback" v-if="!$v.value.required">Value is required</label>
            </b-form-group>

            <b-button type="submit" variant="primary">Add</b-button>
        </b-form>
    </b-modal>
</template>

<script>
    import {AppSettingMethods} from "@/state/helpers";
    import {required} from "vuelidate/lib/validators";

    export default {
        data() {
            return {
                msg: '',
                error: false,
                isValid: false,
                key: '',
                value: '',
                setting_id: 0,
            };
        },

        props: {
            modal_placeholderA: {
                type: String,
            },
            modal_placeholderB: {
                type: String,
            }
        },
        validations: {
            key: {
                required
            },
            value: {
                required
            },
        },
        methods: {
            ...AppSettingMethods,
            // reset all fields of modal on click
            resetModal() {
                this.key = '';
                this.value = '';
                this.msg = '';
                this.error = false;
                this.isValid = false;
            },
            // hit api to add setting
            add_setting() {
                this.msg = '';
                this.isValid = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.addAppSetting({key: this.key, value: this.value}).then(res => {
                    this.$refs['modal'].hide();
                    if (res.status == 200) {
                        this.msg = res.message ? res.message : "Setting added successfully"
                        this.$swal({
                            icon: 'success',
                            text: this.msg,
                            toast: true,
                            showCloseButton: false,
                            showConfirmButton: false,
                            timer: 3000
                        }).then(
                            this.getAllAppSetting(),
                        );
                    }
                }).catch(error => {
                    this.error = true;
                    if (error.data.status == 422) {
                        this.msg = error.data.error;
                    } else {
                        this.$swal({
                            icon: 'error',
                            toast: true,
                            text: "Failed to save! Try again",
                            showCloseButton: false,
                            showConfirmButton: false,
                            timer: 3000
                        });
                    }
                })
            },
        },
        mounted() {

        }
    };
</script>
