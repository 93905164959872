<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <span class="card-title h4">App Settings</span>
<!--                        <button class="float-right btn btn-primary" v-b-modal.addSettingModal @click="addSettingModal">-->
<!--                            Add-->
<!--                        </button>-->
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
<!--                                <div id="tickets-table_length" class="dataTables_length">-->
<!--                                    <label class="d-inline-flex align-items-center">-->
<!--                                        Show-->
<!--                                        <b-form-select v-model="perPage" size="sm"-->
<!--                                                       :options="pageOptions"></b-form-select>-->
<!--                                        entries-->
<!--                                    </label>-->
<!--                                </div>-->
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="settings"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(actions)="setting">
                                    <button class="btn btn-primary btn-sm" v-b-modal.editSettingModal
                                            @click="edit_setting(setting)">Edit
                                    </button>&nbsp;
<!--                                    <button class="btn btn-danger btn-sm" @click="delete_setting(setting)">Delete</button>&nbsp;-->
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows"
                                                      :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddSettingsModal v-if="add_form" :modal_placeholderA="'Enter key'" :modal_placeholderB="'Enter value'"/>
        <EditSettingsModal v-if="edit_form" :selected_id="getId" :selected_key="setting_key"
                           :selected_value="setting_value"/>
    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import AddSettingsModal from "./add_setting";
    import EditSettingsModal from "./edit_setting";
    import {AppSettingMethods} from "@/state/helpers";
    import appConfig from "@/app.config";
    import moment from 'moment';

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "App Settings",
            meta: [{name: "description", content: appConfig.description}]
        },
        components: {
            AddSettingsModal,
            EditSettingsModal,
            Layout, PageHeader
        },
        data() {
            return {
                title: 'App Settings',
                items: [],
                msg: "",
                add_form: false,
                edit_form: false,
                getId: 0,
                setting_key: "",
                setting_value: "",
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "id",
                sortDesc: true,
                fields:
                    [
                        {key: "key", label: 'Key', sortable: true},
                        {key: "value", label: 'Value', sortable: true},
                        {key: "actions", label: 'Actions', sortable: false},
                    ]
            };
        },
        computed: {
            rows() {
                return this.$store.state.appSetting.settings.length;
            },
            settings() {
                return this.$store.state.appSetting.settings
            }
        },
        methods: {
            ...AppSettingMethods,

            delete_setting(setting) {
                this.$swal({
                    icon: 'warning',
                    text: 'Are you sure to delete',
                    showCancelButton: true,
                    toast: true,
                    confirmButtonColor: 'green',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then(result => {
                    if (result.value) {
                        this.msg = '';
                        this.deleteAppSetting({id: setting.item.id}).then(res => {
                            if (res.status === 200) {
                                this.msg = res.message ? res.message : "Setting deleted successfully"
                                this.$swal({
                                    icon: 'success',
                                    text: this.msg,
                                    toast: true,
                                    showCloseButton: false,
                                    showConfirmButton: false,
                                    timer: 3000
                                }).then(
                                    this.getAppSetting()
                                );
                            }
                        }).catch(error => {
                            this.msg = error ? error : "Something went wrong!!!"
                            this.$swal({
                                icon: 'error',
                                text: this.msg,
                                toast: true,
                                showCloseButton: false,
                                showConfirmButton: false,
                                timer: 3000
                            }).then(
                                this.getAppSetting()
                            );
                            this.unauthorizedError(error);
                        });
                    }
                });
            },
            unauthorizedError(err) {
                if (err.data.status === 401) {
                    return this.$router.push('/logout');
                }
            },
            // set the date format
            format_date(value) {
                return moment(value).format("DD MM YYYY")
            },

            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            // onclick to show data model of edit settings
            edit_setting(setting) {
                this.getId = setting.item.id;
                this.setting_key = setting.item.key;
                this.setting_value = setting.item.value;
                this.edit_form = true;
            },

            // onclick to show data model of add setting
            addSettingModal() {
                this.add_form = true;
            },
            getAppSetting() {
                this.getAllAppSetting().then().catch(error => {
                    this.unauthorizedError(error);
                });

            }
        },
        mounted() {
            this.getAppSetting();
        },
        created() {
        },
    };
</script>
